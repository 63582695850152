import ErrorComponent from '@/Shared/ErrorComponent'
import crypto from 'crypto'
import Error from 'next/error'

// validateSignature
export const isTradlyIntegrationHaveValidateSignature = (payload, providedSig, secret) => {
  try {
    const calculatedSig = crypto.createHmac('sha256', secret).update(payload).digest('hex')

    return calculatedSig === providedSig
  } catch (error) {
    console.error('Error validating signature:', error)
    return false
  }
}

export const check_tradly_auth_integration_payload = ({ component, sso, sig }) => {
  const decodedPayload = decodeBase64(sso)
  const { nonce, return_url } = decodedPayload ? JSON.parse(decodedPayload) : {}

  const TradlyIntegrationConnectSecret = process.env.TRADLY_AUTH_INTEGRATION_SSO_SECRET

  const isValidSignature = isTradlyIntegrationHaveValidateSignature(
    decodedPayload,
    sig,
    TradlyIntegrationConnectSecret
  )

  if (isValidSignature) {
    return component
  } else {
    return <ErrorComponent />
  }
}

const decodeBase64 = (encodedString) => {
  try {
    const decodedString = atob(encodedString)
    return decodedString
  } catch (error) {
    console.error('Error decoding Base64:', error)
    return null
  }
}

export const tradly_integration_redirect = ({ sso, sig, router, user }) => {
  const decodedPayload = decodeBase64(sso)
  const { nonce, return_url } = JSON.parse(decodedPayload)

  const payloadObj = {
    nonce: nonce,
    external_id: user.id,
    email: user.email,
    username: user.email,
    first_name: user.name,
    last_name: '',
    email_verified: user.email_verified,
  }

  const encodedPayload = btoa(JSON.stringify(payloadObj))

  const discourseConnectSecret = process.env.TRADLY_AUTH_INTEGRATION_SSO_SECRET

  const hmac = crypto.createHmac('sha256', discourseConnectSecret)
  hmac.update(JSON.stringify(payloadObj))
  const signature = hmac.digest('hex')

  const queryParams = new URLSearchParams({
    payload: encodedPayload,
    sig: signature,
  })
  window.location.href = `${decodeURIComponent(return_url)}?${queryParams.toString()}`
}
