import React from 'react'

const CustomLoading = ({ decrease_width }) => {
  return (
    <div
      className={[' fixed h-screen top-0 right-0 bg-gray-500   bg-opacity-25', ,].join(' ')}
      style={{
        zIndex: '999999999999999999999999999999999999999999999999999',
        width: decrease_width ? `calc(100VW - ${decrease_width}px)` : '100vW',
      }}
    >
      <div className=" w-full h-full flex justify-center items-center">
        <div
          className="
      animate-spin
      rounded-full
      h-32
      w-32
      border-t-2 border-b-2 border-primary 
    "
        ></div>
      </div>
    </div>
  )
}

export default CustomLoading
