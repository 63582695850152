import Link from 'next/link'
import Google from './Google'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { uuid } from 'uuidv4'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Alert from '../../Shared/Alert/Alert'
import Button_loading from '../../Shared/Loader/Button_loading'
import {
  auth_cookie_name,
  cookies_domain_name,
  default_site_url,
  global_auth_cookie_name,
  refresh_cookie_name,
  tenant_cookie_name,
  uuid_cookie_name,
} from '../../../constant/url'
import { deleteCookie, setCookie } from 'cookies-next'
import { decryptAuthKey, encryptDataWithAES, getAuthKey } from '../../../constant/functions'
import mixpanel from 'mixpanel-browser'
import {
  MixpanelEventName,
  MixpanelIdentifyCall,
  MixpanelPropertiesName,
  MixpanelTracking,
} from 'constant/mixpnael'
import CustomLoading from '@/Shared/CustomLoading'
import { sign_in_handler } from './actions/SignIn'

const Sign_in = ({
  sso,
  sig,
  is_log_in_by_discourse,
  is_log_in_by_tradly_integration,
  isLoggedIn,
}) => {
  const router = useRouter()
  const redirect_url = router.query.to || default_site_url

  // Alert
  const [open_alert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [alert_type, setAlert_type] = useState('success')

  //
  const [isLoading, setIsLoading] = useState(false)
  const [countries, setCountries] = useState(null)

  // react hook form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  //
  useEffect(() => {
    setValue('uuid', uuid())
    setValue('type', 'tenant')

    axios
      .get('/api/countries')
      .then((res) => {
        setCountries(res.data.countries_res?.countries)
        if (res.data.IP_COUNTRY) {
          setValue(
            'metadata.country_id',
            res.data.countries_res?.countries.find((cn) => cn.code2 == res.data.IP_COUNTRY).id
          )
        } else {
          setValue('metadata.country_id', res.data.countries_res?.countries[0].id)
        }
      })
      .catch((err) => {
        const message = err?.response?.data?.message
        setIsLoading(false)

        if (message) {
          setOpenAlert(true)
          setAlert_type('error')
          setMessage(message)
        } else {
          if (message) {
            setOpenAlert(true)
            setAlert_type('error')
            setMessage('Something is wrong , please try latter')
          }
        }
      })
  }, [router])

  // Submit login form
  const onSubmit = (data) => {
    sign_in_handler({
      setIsLoading,
      data,
      is_log_in_by_discourse,
      is_log_in_by_tradly_integration,
      redirect_url,
      sso,
      sig,
      router,
      setOpenAlert,
      setAlert_type,
      setMessage,
    })
  }

  return (
    <>
      {/* Alert */}
      <Alert
        className={` w-full md:w-[400px] lg:w-[450px]   fixed bottom-0 md:bottom-5   right-0 z-[9999999999999999999999999999999999999999999999999] `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
      >
        <p>{message}</p>
      </Alert>
      {isLoggedIn && sso && sig && <CustomLoading />}
      <div>
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-16 pb-12 md:pt-20 md:pb-20 ">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Welcome back. Sign in</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-800 text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        {...register('email')}
                        className="form-input w-full text-gray-800"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label
                          className="block text-gray-800 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        {/* <Link
													href={
														"/reset-password"
													}
												>
													<a className="text-sm font-medium text-blue-600 hover:underline">
														Having
														trouble
														signing
														in?
													</a>
												</Link> */}
                      </div>
                      <input
                        id="password"
                        type="password"
                        {...register('password')}
                        className="form-input w-full text-gray-800"
                        placeholder="Enter your password"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="flex flex-wrap -mx-3 mb-4">
									<div className="w-full px-3">
										<div className="flex justify-between">
											<label className="flex items-center">
												<input
													type="checkbox"
													className="form-checkbox"
												/>
												<span className="text-gray-600 ml-2">
													Keep
													me
													signed
													in
												</span>
											</label>
										</div>
									</div>
								</div> */}
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`btn text-white bg-blue-600 hover:bg-blue-700 w-full flex items-center justify-center gap-4 ${
                          isLoading && 'cursor-not-allowed'
                        }`}
                      >
                        <span>Sign in</span>
                        {isLoading && <Button_loading text_color={'text-white'} />}
                      </button>
                    </div>
                  </div>
                </form>
                {/* <div className="flex items-center my-6">
								<div
									className="border-t border-gray-300 flex-grow mr-3"
									aria-hidden="true"
								></div>
								<div className="text-gray-600 italic">
									Or
								</div>
								<div
									className="border-t border-gray-300 flex-grow ml-3"
									aria-hidden="true"
								></div>
							</div>
							<form>
								<Google />
							</form> */}
                <div className="text-gray-600 text-center mt-6">
                  <p className="mb-4">
                    <Link href={`/forgot_password`}>
                      <a className="  text-blue-600 hover:underline transition duration-150 ease-in-out">
                        Forgot password?
                      </a>
                    </Link>
                  </p>
                  <p>
                    Don’t you have an account?
                    <Link
                      href={{
                        pathname: '/signup',
                        query: router.query ?? { to: 'https://superadmin.tradly.app' },
                      }}
                    >
                      <a className=" ml-2 text-blue-600 hover:underline transition duration-150 ease-in-out">
                        Sign up
                      </a>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Sign_in
