import ErrorComponent from '@/Shared/ErrorComponent'
import crypto from 'crypto'
import Error from 'next/error'

// validateSignature
export const validateSignature = (payload, providedSig, secret) => {
  try {
    const hmac = crypto.createHmac('sha256', secret)
    hmac.update(payload)
    const calculatedSig = crypto.createHmac('sha256', secret).update(payload).digest('hex')

    return calculatedSig === providedSig
  } catch (error) {
    console.error('Error validating signature:', error)
    return false
  }
}

export const check_discourse_payload = ({ component, sso, sig }) => {
  const decodedPayload = decodeBase64(sso)
  const { nonce, return_sso_url } = decodedPayload
    ? JSON.parse('{"' + decodeURI(decodedPayload?.replace(/&/g, '","').replace(/=/g, '":"')) + '"}')
    : {}

  const discourseConnectSecret = process.env.DISCOURSE_SSO_SECRET

  const isValidSignature = validateSignature(sso, sig, discourseConnectSecret)

  if (isValidSignature) {
    return component
  } else {
    return <ErrorComponent />
  }
}

const decodeBase64 = (encodedString) => {
  try {
    const decodedString = atob(encodedString)
    return decodedString
  } catch (error) {
    console.error('Error decoding Base64:', error)
    return null
  }
}

export const discourse_redirect = ({ sso, sig, router, user }) => {
  const decodedPayload = decodeBase64(sso)
  const { nonce, return_sso_url } = JSON.parse(
    '{"' + decodeURI(decodedPayload.replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
  )

  const payloadObj = {
    nonce: nonce,
    email: user.email,
    external_id: user.id,
    username: user.email,
    name: user.name,
  }

  const encodedPayload = btoa(new URLSearchParams(payloadObj).toString())

  const discourseConnectSecret = process.env.DISCOURSE_SSO_SECRET

  const hmac = crypto.createHmac('sha256', discourseConnectSecret)
  hmac.update(encodedPayload)
  const signature = hmac.digest('hex')

  const queryParams = new URLSearchParams({
    sso: encodedPayload,
    sig: signature,
  })

  window.location.href = `${decodeURIComponent(return_sso_url)}?${queryParams.toString()}`
}
